import { Avatar, Button, Col, ConfigProvider, Drawer, Layout, Row } from "antd";
import { Color } from "components/consts/Consts";
import { useAuth } from "global/context/AuthenticationContext";
import { Route } from "navigation/AppMenu";
import React, { useMemo, useState } from "react";
import { IoMdMenu } from "react-icons/io";
import SideNavigation from "./SideNavigation";
import { useLocation } from "react-router-dom";
import { ColorFilter } from "utils/format/ColorFilter";

const { Content, Sider } = Layout;

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const getMenuItem = (
  label: any,
  key: any,
  icon: any = null,
  children: any = null,
  type: any = null,
) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

function Main({ children }: any) {
  const [visible, setVisible] = useState(false);
  const { config } = useAuth();
  const pathname = useLocation();

  const sideNav = useMemo(() => {
    let sideNav = config.menu.map((menu) => {
      return {
        item: new Route(
          menu.path,
          menu.name,
          menu.path,
          menu.icon,
          menu.componentName,
        ),
        children:
          menu.subMenu?.length > 0
            ? menu.subMenu.map(
              (subMenu) =>
                new Route(
                  subMenu.path,
                  subMenu.name,
                  subMenu.path,
                  subMenu.icon,
                  subMenu.componentName,
                ),
            )
            : undefined,
      };
    });
    return sideNav;
  }, [config]);

  const itemsNav = useMemo(() => {
    return sideNav.map((nav) => {
      return getMenuItem(
        nav.item.title,
        nav.item.path,
        <img
          src={nav.item.icon}
          alt={`main-${nav.item.key}`}
          style={{
            filter: ColorFilter(
              pathname?.pathname?.includes(nav?.item?.path)
                ? Color.PRIMARY
                : "none",
            ),
            width: 14,
            height: 14,
          }}
        />,
        nav.children?.map((subNav: any) => {
          return getMenuItem(
            subNav.title,
            subNav.path,
            <img
              src={subNav.icon}
              alt={`child-${subNav.title}`}
              style={{
                filter: ColorFilter(
                  pathname?.pathname?.includes(subNav?.path)
                    ? Color.PRIMARY
                    : "none",
                ),
                width: 14,
                height: 14,
              }}
            />,
          );
        }),
      );
    });
  }, [sideNav]);

  const renderDrawerMode = () => {
    return (
      <Drawer
        title={false}
        placement={"left"}
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
        key={"left"}
        width={275}
      >
        <Layout style={{ width: "100%" }}>
          <Sider
            collapsedWidth="0"
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            trigger={null}
            width="100%"
            theme="light"
          >
            <SideNavigation sideNav={sideNav} itemsNav={itemsNav} />
          </Sider>
        </Layout>
      </Drawer>
    );
  };

  const renderNormalMode = () => {
    return (
      <>
        <Sider
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
          width={260}
          theme="light"
          className={"sidebar-responsive"}
          style={{ backgroundColor: "white" }}
        >
          <SideNavigation sideNav={sideNav} itemsNav={itemsNav} />
        </Sider>

        <Layout
          style={{ minHeight: "80vh", overflowY: "auto", overflowX: "hidden" }}
        >
          <Content>{children}</Content>
        </Layout>
      </>
    );
  };

  return (
    <>
      <Row className="drawer-responsive" style={{ backgroundColor: "white" }}>
        <Col span={24}>
          <Button
            type="link"
            className=""
            onClick={() => setVisible(!visible)}
            icon={<IoMdMenu style={{ color: "black", fontSize: 20 }} />}
            size="large"
          />
        </Col>
      </Row>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: Color.PRIMARY,
          },
        }}
      >
        <Layout
          style={{
            flex: 1,
            minHeight: "100vh",
          }}
        >
          {renderDrawerMode()}
          {renderNormalMode()}
        </Layout>
      </ConfigProvider>
    </>
  );
}

export default Main;
