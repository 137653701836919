import BaseApiResponseModel from "api/model/BaseApiResponseModel";
import axios from "axios";
import ModelConverter from "utils/format/ModelConverter";
import IAPIClient from "./APIClient";

const LOCAL_ACCESS_TOKEN = "LA";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL!,
  timeout: 60000,
});

api.interceptors.request.use(
  (config) => {
    //Get Tokens
    const token = localStorage.getItem(LOCAL_ACCESS_TOKEN);

    //If there is a token ?
    if (token) {
      //Request headers
      // config.headers!["ngrok-skip-browser-warning"] = "69420" //For NGROK
      config.headers["Authorization"] = `Bearer ${token}`
    }
    return config;
  },
  (error) => Promise.reject(error),
);

//Response interceptors
api.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  (error) => {
    console.error(error)
    if (error?.response?.status === 403) {
      if (!!localStorage.getItem(LOCAL_ACCESS_TOKEN)) {
        localStorage.removeItem(LOCAL_ACCESS_TOKEN);
        localStorage.removeItem("CONFIG");
        window.location.href = '/sign-in'; //relative to domain
      }
    };
    return Promise.resolve(error?.response?.data);
  },
);

class AxiosClient implements IAPIClient {
  async post<T extends Object>(
    path: string,
    data: Map<string, any> | any = {},
    config?: Map<string, any> | any,
  ): Promise<BaseApiResponseModel<T>> {
    let response = await api.post(path, data, config);
    return ModelConverter.decode(response, BaseApiResponseModel<T>);
  }

  async get<T extends Object>(
    path: string,
    data: Map<string, any> | any = {},
  ): Promise<BaseApiResponseModel<T>> {
    let response = await api.get(path, {
      params: data,
      paramsSerializer: {
        indexes: true,
      },
    });
    return ModelConverter.decode(response, BaseApiResponseModel<T>);
  }

  async delete<T extends Object>(
    path: string,
    data: Map<string, any> | any = {},
  ): Promise<BaseApiResponseModel<T>> {
    let response = await api.delete(path, {
      params: data,
    });
    return ModelConverter.decode(response, BaseApiResponseModel<T>);
  }

  async put<T extends Object>(
    path: string,
    data: Map<string, any> | any = {},
  ): Promise<BaseApiResponseModel<T>> {
    let response = await api.put(path, data);
    return ModelConverter.decode(response, BaseApiResponseModel<T>);
  }

  async export(path: string, data: Map<string, any> | any = {}): Promise<any> {
    const response = await api.get(path, {
      params: data,
      responseType: "arraybuffer",
    });
    return response;
  }
}

export default new AxiosClient();
