export default class BaseApiResponseModel<T extends Object> {
  map(arg0: (data: any) => { time: string; value: any; count: any; }) {
    throw new Error("Method not implemented.");
  }

  constructor(
    public data?: T,
    public paging?: Paging,
    public pagination?: Paging,
    public message?: string,
    public error?: Error,
  ) {

  }
}

export interface Paging {
  limit: number;
  page: number;
  total: number;
}

export interface Error {
  code?: number;
  message?: string;
}
