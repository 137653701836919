export const Consts = {
  primary: "",
  secondary: "",
  outline: "",
  background: "",
  white: "#fff"
}

export const Spacing = {
  XXS: 4,
  XS: 8,
  S: 12,
  M: 16,
  L: 24,
  XL: 32,
  XXL: 40,
  XXXL: 54
}

export const Radius = {
  XS: 4,
  S: 8,
  M: 12,
  L: 16,
  XL: 24
}

export const Color = {
  PRIMARY: '#fa8c15',
  SECONDARY: '#FFF7E6',
  GREEN: "#15803d",
  BLUE: '#3D5CC0',
  RED: "#DD5746",
  PURPLE: "#86469C",
  WHITE: "#fff",
  PRIMARY_GRADIENT: "linear-gradient(to bottom right, #ffffff, 80%, #ece9e6)",
  BOX_SHADOW: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"
}
