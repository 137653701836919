
export enum UserRole {
    Admin = "admin",
    Accounter = "accounter",
    Merchant = "merchant"
}


export default class LoginResponseModel {
    createdAt?: Date;
    updatedAt?: Date;
    id?: string;
    username?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    birthDay?: Date;
    address?: string;
    phone?: string;
    role?: UserRole;
    description?: string;
    money?: number;
    merchantProvider?: string;
    accessToken?: AccessToken;
    enable2FA?: boolean;
    subscription?: Subscription;

    isAdmin(): boolean {
        return this.role === UserRole.Admin;
    }

    isAccountant(): boolean {
        return this.role === UserRole.Accounter;
    }

    isMerchant(): boolean {
        return this.role === UserRole.Merchant;
    }
}


export interface Subscription {
    id?:          string;
    name?:        string;
    displayName?: string;
    address?:     string;
    description?: string;
    code?:        string;
    cover?:       string;
    website?:     string;
    warehouse?:   null;
    balance?:     string;
}


export class AccessToken {
    id?: string;
    ttl?: number;
    created?: Date;
    expired?: number;
}
