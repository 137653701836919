import { useAuth } from "global/context/AuthenticationContext";
import "./App.css";

//For transform json
import { Skeleton } from "antd";
import { MenuItem } from "api/config/model/AppConfigResponseModel";
import Main from "components/layout/MainAppLayout";
import { HOST_MENUS } from "navigation/AppMenu";
import AppPages from "navigation/AppPages";
import {
  FC,
  lazy,
  LazyExoticComponent,
  ReactNode,
  Suspense,
  useMemo,
} from "react";
import { Route, Routes } from "react-router";
import "reflect-metadata";

const DataPackageTransactionFeature: LazyExoticComponent<FC> = lazy(
  () =>
    import(
      "features/transactionHistory/dataPackage/view/DataPackageTransactionFeature"
    ),
);
console.log("CMS B2B")

function App() {
  const { isAuthenticated, profile, config } = useAuth();

  const mapMenuItemToRoute = (menu: MenuItem): any => {
    const Component = AppPages.getPage(menu.componentName);
    return (
      <Route
        key={menu.path}
        path={menu.path}
        element={
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-screen w-screen">
                <div className="loader"></div>
              </div>
            }
          >
            <Component />
          </Suspense>
        }
      />
    );
  };

  const routes = useMemo(() => {
    let routes: Array<ReactNode> = [];
    config?.menu?.forEach((menu) => {
      routes.push(mapMenuItemToRoute(menu));

      //Temp Limit depth 1
      if (menu.subMenu?.length > 0) {
        menu.subMenu.forEach((subMenu) => {
          routes.push(mapMenuItemToRoute(subMenu));
        });
      }
    });
    return routes;
  }, [config]);

  const renderHostRoutes = () => {
    return (
      <Routes>
        {HOST_MENUS.map((menu: MenuItem) => {
          const Component = AppPages.getPage(menu.componentName);
          return (
            <Route
              key={menu.path}
              path={menu.path}
              element={
                <Suspense
                  fallback={
                    <div className="flex justify-center items-center h-screen w-screen">
                      <div className="loader"></div>
                    </div>
                  }
                >
                  <Component />
                </Suspense>
              }
            />
          );
        })}
      </Routes>
    );
  };

  const renderContent = () => {
    if (!isAuthenticated) {
      return renderHostRoutes();
    } else if (!config?.menu) {
      return (
        <div className="flex justify-center items-center h-screen w-screen">
          <div className="loader"></div>
        </div>
      );
    }

    return (
      <Main>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center items-center h-screen w-screen">
                    <div className="loader"></div>
                  </div>
                }
              >
                <DataPackageTransactionFeature />
              </Suspense>
            }
          />
          {routes}
        </Routes>
      </Main>
    );
  };
  return <div className="App">{renderContent()}</div>;
}

export default App;
