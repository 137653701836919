import LoginResponseModel, {
  UserRole,
} from "api/account/model/LoginResponseModel";
import AppConfigResponseModel from "api/config/model/AppConfigResponseModel";
import ConfigRepository from "api/config/model/ConfigRepository";
import {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import ModelConverter from "utils/format/ModelConverter";
import AccountRepository from "api/account/AccountRepository";

export const AuthContext = createContext({});

const LOCAL_ACCESS_TOKEN = "LA";
const CONFIG = "CONFIG";

export const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<any>(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [profile, setProfile] = useState<LoginResponseModel | undefined>(
    undefined,
  );

  const [config, setConfig] = useState<AppConfigResponseModel | undefined>(
    undefined,
  );

  const onLogout = useCallback(() => {
    localStorage?.clear();
    setToken(undefined);
    setProfile(undefined);
    setIsAuthenticated(false);
    navigate("/sign-in");
  }, [navigate]);

  const onLogged = async (model: LoginResponseModel) => {
    if (model.role) {
      const accessToken = model?.accessToken?.id || "";
      localStorage.setItem(LOCAL_ACCESS_TOKEN, accessToken);
      setToken(accessToken);
      let appConfig = await ConfigRepository.getAppConfig(model?.role);
      setConfig(appConfig.data);
      let accountProfileRes = await AccountRepository.getProfile();
      let profile = accountProfileRes?.data;
      setProfile(profile);
      navigate("/history/data-package");
    }
  };

  const getAppConfig = async (role: UserRole) => {
    const configData = localStorage.getItem(CONFIG);
    if (!configData) {
      let appConfig = await ConfigRepository.getAppConfig(role);
      if (appConfig && appConfig.data) {
        const dataString = JSON.stringify(appConfig.data);
        localStorage.setItem(CONFIG, dataString);
        setConfig(appConfig.data);
      }
    } else {
      const appConfig = ModelConverter.decode(
        JSON.parse(configData),
        AppConfigResponseModel,
      );
      setConfig(appConfig);
    }
  };

  const onInit = async () => {
    const token = localStorage.getItem(LOCAL_ACCESS_TOKEN);
    if (!!token) {
      let accountProfileRes = await AccountRepository.getProfile();
      //TODO: Recheck here
      let profile = ModelConverter.decode(accountProfileRes?.data,LoginResponseModel);
      setProfile(profile);
      profile?.isMerchant();
      setToken(token);
      setIsAuthenticated(true);
    } else {
      if (window.location.pathname === "/sign-up") {
        navigate("/sign-up");
      } else {
        navigate("/sign-in");
      }
    }
  };

  useEffect(() => {
    onInit();
  }, [navigate]);

  useEffect(() => {
    if (!!profile?.role) {
      getAppConfig(profile?.role);
    }
  }, [profile]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        onLogged,
        onLogout,
        profile,
        config,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface AuthenticationProps {
  onLogged: any;
  onLogout: any;
  token: string;
  profile: LoginResponseModel;
  config: AppConfigResponseModel;
  onSignUp: any;
  onNeedFetchUserProfile: any;
  isAuthenticated: boolean;
  loading: boolean;
}

export const useAuth = (): AuthenticationProps =>
  useContext(AuthContext) as AuthenticationProps;
