import ApiPath from "api/ApiPath";
import client from "api/client";
import BaseApiResponseModel from "api/model/BaseApiResponseModel";
import LoginRequestModel from "./model/LoginRequestModel";
import LoginResponseModel from "./model/LoginResponseModel";

export interface IAccountRepository {
  getProfile(): Promise<BaseApiResponseModel<LoginResponseModel>>
  login(props: LoginRequestModel): Promise<BaseApiResponseModel<LoginResponseModel>>
}

class AccountRepositoryImpl implements IAccountRepository {
  async getProfile(): Promise<BaseApiResponseModel<LoginResponseModel>> {
    return await client.get<LoginResponseModel>(ApiPath.GET_PROFILE);
  }

  async login(props: LoginRequestModel): Promise<BaseApiResponseModel<LoginResponseModel>> {
    return await client.post(ApiPath.LOGIN,props);
  }
}

export default new AccountRepositoryImpl();