// import { useState } from "react";
import { Avatar, Col, ConfigProvider, Image, Menu, Modal, Popover, Row } from "antd";
import { Color } from "components/consts/Consts";
import strings from "components/strings/LocalizedStrings";
import { useAuth } from "global/context/AuthenticationContext";
import { isEmpty } from "lodash";
import { Route } from "navigation/AppMenu";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { colorTextDisplay, CurrencyDisplay, RoleDisplay } from "utils/display/ColumnRenderHelper";

function SideNavigation({ sideNav, itemsNav, onClick }: any) {
  const { pathname } = useLocation();
  let path = pathname.replace?.("/", "");
  const { onLogout } = useAuth();
  const navigate = useNavigate();
  const { profile } = useAuth();

  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([
    `/${pathname?.split("/")?.[1]}`,
  ]);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([
    pathname,
  ]);

  useEffect(() => {
    onClickMenuItem({ key: path });
  }, []);

  const getItem = useCallback(
    (path: string): Route | undefined => {
      let foundedItem: any;
      sideNav.forEach((e: any) => {
        if (e.item.path.includes(path)) {
          return (foundedItem = e);
        }

        e.children?.forEach((e: any) => {
          if (e.path.includes(path)) {
            return (foundedItem = e);
          }
        });
      });
      return foundedItem;
    },
    [sideNav],
  );

  const onClickMenuItem = (e: any) => {
    let path = e.key;
    let item = getItem(path);

    onClick?.(item);
    if (e.key === "/sign-out") {
      return Modal.confirm({
        title: strings.LogOutConfirm,
        okText: strings.OK,
        okType: "danger",
        okCancel: true,
        centered: true,
        autoFocusButton: "cancel",
        cancelText: strings.Cancel,
        onOk: () => onLogout(),
      });
    } else {
      navigate(e.key);
    }
  };

  return (
    <>
      <Row style={{ padding: "10% 12%" }}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Image
            src={require("assets/images/logo_mobifone.png")}
            alt="sidebarLogo"
            preview={false}
          />
        </Col>
      </Row>
      <Row style={{ padding: "5% 12%" }} justify={"start"} align={"middle"}>
        <Avatar
          src={require("assets/images/user.png")}
          alt="sidebarLogo"
          shape="circle"
          size={48}
          style={{ marginRight: "10px" }}
        />
        <div style={{ display: "flex", alignItems: "between", flexDirection: "column" }}>
          <div style={{ fontSize: "16px", fontWeight: "bold", color: Color.PRIMARY }}>{profile?.username}</div>
          <div>
            <span style={{ color: "gray" }}>{strings.UserManager.Titles.Role}: </span>
            <span style={{ fontWeight: "bold" }}>{RoleDisplay(profile?.role)}</span>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>{colorTextDisplay(CurrencyDisplay(+profile?.subscription?.balance), +profile?.subscription?.balance === 0 ? "red" : "green")}</span>
          </div>

        </div>
      </Row>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemHoverBg: Color.SECONDARY,
              subMenuItemBg: Color.WHITE
            },
          },
        }}
      >
        <Menu
          style={{
            background: "transparent",
            borderInlineEnd: 'none',
            fontWeight: 600,
            maxHeight: "calc(100vh - 160px)",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}
          theme="light"
          mode="inline"
          onClick={onClickMenuItem}
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          items={itemsNav}
        />
      </ConfigProvider>
    </>
  );
}

export default SideNavigation;
