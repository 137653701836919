import { UserRole } from "api/account/model/LoginResponseModel";
import { defaultMenuConfigRepository } from "api/menuConfig/MenuConfigRepository";
import BaseApiResponseModel from "api/model/BaseApiResponseModel";
import strings from "components/strings/LocalizedStrings";
import AppConfigResponseModel, { MenuItem } from "./AppConfigResponseModel";

export interface IConfigRepository {
  getAppConfig(
    role: UserRole,
  ): Promise<BaseApiResponseModel<AppConfigResponseModel>>;
}

export class MockConfigRepository implements IConfigRepository {
  getAppConfig(
    role: UserRole,
  ): Promise<BaseApiResponseModel<AppConfigResponseModel>> {
    return new Promise(async (resolve, reject) => {
      let menuItems: MenuItem[] = [];
      switch (role) {
        case UserRole.Admin:
          //Admin
          menuItems = [
            new MenuItem(
              strings.Navbar.HomeFeature,
              "/generals",
              "DashboardFeature",
              "https://img.icons8.com/?size=100&id=8322&format=png",
              [],
            ),
            new MenuItem(
              strings.Navbar.TransactionHistory,
              "/history",
              "",
              "https://img.icons8.com/?size=100&id=c9jL65oRLCWn&format=png",
              [
                new MenuItem(
                  strings.Navbar.DataPackages,
                  "/history/data-package",
                  "DataPackageTransactionFeature",
                  "https://img.icons8.com/?size=100&id=88319&format=png",
                ),
                // new MenuItem(
                //   strings.Navbar.SimPackages,
                //   "/history/sim-package",
                //   "SimPackageFeature",
                //   "https://img.icons8.com/?size=100&id=ntyOTnvz2lIF&format=png",
                // ),
                new MenuItem(
                  strings.Navbar.Balance,
                  "/history/limit",
                  "BalanceFeature",
                  "https://img.icons8.com/?size=100&id=21448&format=png",
                ),
              ],
            ),
            new MenuItem(
              strings.Navbar.Manager,
              "/manager",
              "",
              "https://img.icons8.com/?size=100&id=123488&format=png",
              [
                new MenuItem(
                  strings.Navbar.Packages,
                  "/manager/data-packages",
                  "DataPackageFeature",
                  "https://img.icons8.com/?size=100&id=88319&format=png",
                ),
                new MenuItem(
                  strings.Navbar.Partners,
                  "/manager/partners",
                  "PartnerManagementFeature",
                  "https://img.icons8.com/?size=100&id=11168&format=png",
                ),
                new MenuItem(
                  strings.Navbar.WaitingList,
                  "/manager/waiting-list",
                  "WaitingListFeature",
                  "https://img.icons8.com/?size=100&id=10658&format=png",
                ),
                new MenuItem(
                  strings.Navbar.Users,
                  "/manager/user-management",
                  "UserManagementFeature",
                  "https://img.icons8.com/?size=100&id=23265&format=png",
                ),
              ],
            ),
            new MenuItem(
              strings.Navbar.Reports,
              "/report",
              "",
              "https://img.icons8.com/?size=100&id=57715&format=png",
              [
                new MenuItem(
                  strings.Navbar.Revenue,
                  "/report/revenue",
                  "ReportRevenue",
                  "https://img.icons8.com/?size=100&id=4ZeO4eZGkoy9&format=png",
                ),
                new MenuItem(
                  strings.Navbar.Exports,
                  "/report/export",
                  "ExportReportFeature",
                  "https://img.icons8.com/?size=100&id=fHaaiHGsBH4a&format=png",
                ),
              ],
            ),
            new MenuItem(
              strings.Navbar.Settings,
              "/settings",
              "",
              "https://img.icons8.com/?size=100&id=2969&format=png",
              [
                new MenuItem(
                  strings.Navbar.ChangePassword,
                  "/settings/change-password",
                  "ChangePasswordFeature",
                  "https://img.icons8.com/?size=100&id=10480&format=png",
                ),
                new MenuItem(
                  strings.Navbar.Authentication,
                  "/settings/authentication",
                  "AccountAuthenFeature",
                  "https://img.icons8.com/?size=100&id=884&format=png",
                ),
              ],
            ),
            new MenuItem(
              strings.Navbar.SignOut,
              "/sign-out",
              "SignOutFeature",
              "https://img.icons8.com/?size=100&id=8119&format=png",
            ),
          ];
          break;
        case UserRole.Accounter:
          //Account
          menuItems = [
            new MenuItem(
              strings.Navbar.HomeFeature,
              "/generals",
              "DashboardFeature",
              "https://img.icons8.com/?size=100&id=8322&format=png",
              [],
            ),
            new MenuItem(
              strings.Navbar.TransactionHistory,
              "/history",
              "",
              "https://img.icons8.com/?size=100&id=c9jL65oRLCWn&format=png",
              [
                new MenuItem(
                  strings.Navbar.DataPackages,
                  "/history/data-package",
                  "DataPackageTransactionFeature",
                  "https://img.icons8.com/?size=100&id=88319&format=png",
                ),
                // new MenuItem(
                //   strings.Navbar.SimPackages,
                //   "/history/sim-package",
                //   "SimPackageFeature",
                //   "https://img.icons8.com/?size=100&id=ntyOTnvz2lIF&format=png",
                // ),
                new MenuItem(
                  strings.Navbar.Balance,
                  "/history/limit",
                  "BalanceFeature",
                  "https://img.icons8.com/?size=100&id=21448&format=png",
                ),
                // new MenuItem(
                //   strings.Navbar.DataCode,
                //   "transaction-history/data-code",
                //   "HomeFeature",
                //   GrUpdate
                // ),
              ],
            ),
            new MenuItem(
              strings.Navbar.Reports,
              "/report",
              "",
              "https://img.icons8.com/?size=100&id=57715&format=png",
              [
                new MenuItem(
                  strings.Navbar.Revenue,
                  "/report/revenue",
                  "ReportRevenue",
                  "https://img.icons8.com/?size=100&id=4ZeO4eZGkoy9&format=png",
                ),
                new MenuItem(
                  strings.Navbar.Exports,
                  "/report/export",
                  "ExportReportFeature",
                  "https://img.icons8.com/?size=100&id=fHaaiHGsBH4a&format=png",
                ),
              ],
            ),
            new MenuItem(
              strings.Navbar.Settings,
              "/settings",
              "",
              "https://img.icons8.com/?size=100&id=2969&format=png",
              [
                new MenuItem(
                  strings.Navbar.ChangePassword,
                  "/settings/change-password",
                  "ChangePasswordFeature",
                  "https://img.icons8.com/?size=100&id=10480&format=png",
                ),
                new MenuItem(
                  strings.Navbar.Authentication,
                  "/settings/authentication",
                  "AccountAuthenFeature",
                  "https://img.icons8.com/?size=100&id=884&format=png",
                ),
              ],
            ),
            new MenuItem(
              strings.Navbar.SignOut,
              "/sign-out",
              "SignOutFeature",
              "https://img.icons8.com/?size=100&id=8119&format=png",
            ),
          ];
          break;
        case UserRole.Merchant:
          //Merchant
          menuItems = [
            new MenuItem(
              strings.Navbar.HomeFeature,
              "/generals",
              "DashboardFeature",
              "https://img.icons8.com/?size=100&id=8322&format=png",
              [],
            ),
            new MenuItem(
              strings.Navbar.TransactionHistory,
              "/history",
              "",
              "https://img.icons8.com/?size=100&id=c9jL65oRLCWn&format=png",
              [
                new MenuItem(
                  strings.Navbar.DataPackages,
                  "/history/data-package",
                  "DataPackageTransactionFeature",
                  "https://img.icons8.com/?size=100&id=88319&format=png",
                ),
              ],
            ),
            new MenuItem(
              strings.Navbar.Settings,
              "/settings",
              "",
              "https://img.icons8.com/?size=100&id=2969&format=png",
              [
                new MenuItem(
                  strings.Navbar.ChangePassword,
                  "/settings/change-password",
                  "ChangePasswordFeature",
                  "https://img.icons8.com/?size=100&id=10480&format=png",
                ),
                new MenuItem(
                  strings.Navbar.Authentication,
                  "/settings/authentication",
                  "AccountAuthenFeature",
                  "https://img.icons8.com/?size=100&id=884&format=png",
                ),
              ],
            ),
            new MenuItem(
              strings.Navbar.SignOut,
              "/sign-out",
              "SignOutFeature",
              "https://img.icons8.com/?size=100&id=8119&format=png",
            ),
          ];
          break;
        default:
          undefined;
          break;
      }
      // try {
      //   const res = await defaultMenuConfigRepository.getMenuConfig();
      //   if (res?.data) {
      //     let data = JSON.parse(res?.data?.config as string);
      //     let admin = data?.admin;
      //     let accounter = data?.accounter;
      //     let merchant = data?.merchant;
      //     switch (role) {
      //       case UserRole.Admin:
      //         menuItems = admin?.map((item: MenuItem) => {
      //           return new MenuItem(
      //             item?.name,
      //             item?.path,
      //             item?.componentName,
      //             item?.icon,
      //             item?.subMenu?.map((sub: any) => {
      //               return new MenuItem(
      //                 sub?.name,
      //                 sub?.path,
      //                 sub?.componentName,
      //                 sub?.icon,
      //               );
      //             }),
      //           );
      //         });
      //         break;
      //       case UserRole.Accounter:
      //         //Account
      //         menuItems = accounter?.map((item: MenuItem) => {
      //           return new MenuItem(
      //             item?.name,
      //             item?.path,
      //             item?.componentName,
      //             item?.icon,
      //             item?.subMenu?.map((sub: any) => {
      //               return new MenuItem(
      //                 sub?.name,
      //                 sub?.path,
      //                 sub?.componentName,
      //                 sub?.icon,
      //               );
      //             }),
      //           );
      //         });
      //         break;
      //       case UserRole.Merchant:
      //         //Merchant
      //         menuItems = merchant?.map((item: MenuItem) => {
      //           return new MenuItem(
      //             item?.name,
      //             item?.path,
      //             item?.componentName,
      //             item?.icon,
      //             item?.subMenu?.map((sub: any) => {
      //               return new MenuItem(
      //                 sub?.name,
      //                 sub?.path,
      //                 sub?.componentName,
      //                 sub?.icon,
      //               );
      //             }),
      //           );
      //         });
      //         break;
      //       default:
      //         return undefined;
      //     }
      //   } else {
      //     switch (role) {
      //       case UserRole.Admin:
      //         //Admin
      //         menuItems = [
      //           new MenuItem(
      //             strings.Navbar.HomeFeature,
      //             "/generals",
      //             "DashboardFeature",
      //             "https://img.icons8.com/?size=100&id=8322&format=png",
      //             [],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.TransactionHistory,
      //             "/history",
      //             "",
      //             "https://img.icons8.com/?size=100&id=c9jL65oRLCWn&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.DataPackages,
      //                 "/history/data-package",
      //                 "DataPackageTransactionFeature",
      //                 "https://img.icons8.com/?size=100&id=88319&format=png",
      //               ),
      //               // new MenuItem(
      //               //   strings.Navbar.SimPackages,
      //               //   "/history/sim-package",
      //               //   "SimPackageFeature",
      //               //   "https://img.icons8.com/?size=100&id=ntyOTnvz2lIF&format=png",
      //               // ),
      //               new MenuItem(
      //                 strings.Navbar.Balance,
      //                 "/history/limit",
      //                 "BalanceFeature",
      //                 "https://img.icons8.com/?size=100&id=21448&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.PackageRegistration,
      //                 "/history/package-registration",
      //                 "PackageRegistrationFeature",
      //                 "https://img.icons8.com/?size=100&id=21448&format=png",
      //               ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.Manager,
      //             "/manager",
      //             "",
      //             "https://img.icons8.com/?size=100&id=123488&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.Packages,
      //                 "/manager/data-packages",
      //                 "DataPackageFeature",
      //                 "https://img.icons8.com/?size=100&id=88319&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.Partners,
      //                 "/manager/partners",
      //                 "PartnerManagementFeature",
      //                 "https://img.icons8.com/?size=100&id=11168&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.WaitingList,
      //                 "/manager/waiting-list",
      //                 "WaitingListFeature",
      //                 "https://img.icons8.com/?size=100&id=10658&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.Users,
      //                 "/manager/user-management",
      //                 "UserManagementFeature",
      //                 "https://img.icons8.com/?size=100&id=23265&format=png",
      //               ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.Reports,
      //             "/report",
      //             "",
      //             "https://img.icons8.com/?size=100&id=57715&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.Revenue,
      //                 "/report/revenue",
      //                 "ReportRevenue",
      //                 "https://img.icons8.com/?size=100&id=4ZeO4eZGkoy9&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.Exports,
      //                 "/report/export",
      //                 "ExportReportFeature",
      //                 "https://img.icons8.com/?size=100&id=fHaaiHGsBH4a&format=png",
      //               ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.Settings,
      //             "/settings",
      //             "",
      //             "https://img.icons8.com/?size=100&id=2969&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.Accounts,
      //                 "/settings/account",
      //                 "AccountFeature",
      //                 "https://img.icons8.com/?size=100&id=7819&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.ChangePassword,
      //                 "/settings/change-password",
      //                 "ChangePasswordFeature",
      //                 "https://img.icons8.com/?size=100&id=10480&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.Authentication,
      //                 "/settings/authentication",
      //                 "AccountAuthenFeature",
      //                 "https://img.icons8.com/?size=100&id=884&format=png",
      //               ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.SignOut,
      //             "/sign-out",
      //             "SignOutFeature",
      //             "https://img.icons8.com/?size=100&id=8119&format=png",
      //           ),
      //         ];
      //         break;
      //       case UserRole.Accounter:
      //         //Account
      //         menuItems = [
      //           new MenuItem(
      //             strings.Navbar.HomeFeature,
      //             "/generals",
      //             "DashboardFeature",
      //             "https://img.icons8.com/?size=100&id=8322&format=png",
      //             [],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.TransactionHistory,
      //             "/history",
      //             "",
      //             "https://img.icons8.com/?size=100&id=c9jL65oRLCWn&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.DataPackages,
      //                 "/history/data-package",
      //                 "DataPackageTransactionFeature",
      //                 "https://img.icons8.com/?size=100&id=88319&format=png",
      //               ),
      //               // new MenuItem(
      //               //   strings.Navbar.SimPackages,
      //               //   "/history/sim-package",
      //               //   "SimPackageFeature",
      //               //   "https://img.icons8.com/?size=100&id=ntyOTnvz2lIF&format=png",
      //               // ),
      //               new MenuItem(
      //                 strings.Navbar.Balance,
      //                 "/history/limit",
      //                 "BalanceFeature",
      //                 "https://img.icons8.com/?size=100&id=21448&format=png",
      //               ),
      //               // new MenuItem(
      //               //   strings.Navbar.DataCode,
      //               //   "transaction-history/data-code",
      //               //   "HomeFeature",
      //               //   GrUpdate
      //               // ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.Reports,
      //             "/report",
      //             "",
      //             "https://img.icons8.com/?size=100&id=57715&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.Revenue,
      //                 "/report/revenue",
      //                 "ReportRevenue",
      //                 "https://img.icons8.com/?size=100&id=4ZeO4eZGkoy9&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.Exports,
      //                 "/report/export",
      //                 "ExportReportFeature",
      //                 "https://img.icons8.com/?size=100&id=fHaaiHGsBH4a&format=png",
      //               ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.Settings,
      //             "/settings",
      //             "",
      //             "https://img.icons8.com/?size=100&id=2969&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.Accounts,
      //                 "/settings/account",
      //                 "AccountFeature",
      //                 "https://img.icons8.com/?size=100&id=7819&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.ChangePassword,
      //                 "/settings/change-password",
      //                 "ChangePasswordFeature",
      //                 "https://img.icons8.com/?size=100&id=10480&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.Authentication,
      //                 "/settings/authentication",
      //                 "AccountAuthenFeature",
      //                 "https://img.icons8.com/?size=100&id=884&format=png",
      //               ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.SignOut,
      //             "/sign-out",
      //             "SignOutFeature",
      //             "https://img.icons8.com/?size=100&id=8119&format=png",
      //           ),
      //         ];
      //         break;
      //       case UserRole.Merchant:
      //         //Merchant
      //         menuItems = [
      //           new MenuItem(
      //             strings.Navbar.HomeFeature,
      //             "/generals",
      //             "DashboardFeature",
      //             "https://img.icons8.com/?size=100&id=8322&format=png",
      //             [],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.TransactionHistory,
      //             "/history",
      //             "",
      //             "https://img.icons8.com/?size=100&id=c9jL65oRLCWn&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.DataPackages,
      //                 "/history/data-package",
      //                 "DataPackageTransactionFeature",
      //                 "https://img.icons8.com/?size=100&id=88319&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.PackageRegistration,
      //                 "/history/package-registration",
      //                 "PackageRegistrationFeature",
      //                 "https://img.icons8.com/?size=100&id=21448&format=png",
      //               ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.Settings,
      //             "/settings",
      //             "",
      //             "https://img.icons8.com/?size=100&id=2969&format=png",
      //             [
      //               new MenuItem(
      //                 strings.Navbar.Accounts,
      //                 "/settings/account",
      //                 "AccountFeature",
      //                 "https://img.icons8.com/?size=100&id=7819&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.ChangePassword,
      //                 "/settings/change-password",
      //                 "ChangePasswordFeature",
      //                 "https://img.icons8.com/?size=100&id=10480&format=png",
      //               ),
      //               new MenuItem(
      //                 strings.Navbar.Authentication,
      //                 "/settings/authentication",
      //                 "AccountAuthenFeature",
      //                 "https://img.icons8.com/?size=100&id=884&format=png",
      //               ),
      //             ],
      //           ),
      //           new MenuItem(
      //             strings.Navbar.SignOut,
      //             "/sign-out",
      //             "SignOutFeature",
      //             "https://img.icons8.com/?size=100&id=8119&format=png",
      //           ),
      //         ];
      //         break;
      //       default:
      //         undefined;
      //         break;
      //     }
      //   }
      // } catch (error) {
      //   console.error(error);
      //   reject(error);
      // }
      resolve(
        new BaseApiResponseModel<AppConfigResponseModel>(
          new AppConfigResponseModel(menuItems),
          undefined,
          undefined,
          undefined,
          undefined,
        ),
      );
    });
  }
}

export default new MockConfigRepository();
