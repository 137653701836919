export default class AppConfigResponseModel {
  constructor(public menu: MenuItem[]) {}
}

export class MenuItem {
  constructor(
    public name: string,
    public path: string,
    public componentName: string,
    public icon: any,
    public subMenu: MenuItem[] = []
  ) {}
}
