import { MenuItem } from "api/config/model/AppConfigResponseModel";
import { TbLogout } from "react-icons/tb";

export class Route {
  constructor(
    public key: string,
    public title: string,
    public path: string,
    public icon: any,
    public componentName: string
  ) {}
}

export const HOST_MENUS: MenuItem[] = [
  new MenuItem("Đăng nhập", "sign-in", "SignInFeature", TbLogout),
  new MenuItem("Đăng ký", "sign-up", "SignUpFeature", TbLogout),
];
