import { UserRole } from "api/account/model/LoginResponseModel";
import strings from "components/strings/LocalizedStrings";

export function CurrencyDisplay(
  text: string | number | bigint,
  isString?: boolean,
) {
  if (!isString)
    return (
      <span
        className={`font-semibold ${text === 0 ? "text-red-700" : "text-green-700"}`}
      >{`${new Intl.NumberFormat().format(text as number | bigint)} VND`}</span>
    );
  else return `${new Intl.NumberFormat().format(parseInt(text as string))} VND`;
}

export function colorTextDisplay(value: any, color: string | any) {
  return (
    <div style={{ color: color, fontWeight: "bold", fontStyle: "italic" }}>
      {value}
    </div>
  );
}

export function IndexDisplay(
  currentPage: number,
  limit: number,
  index: number,
  item: any,
  record: any,
) {
  const currentIndex = currentPage * limit + index + 1;
  return <span style={{ fontWeight: "bold" }}>{currentIndex}</span>;
}

export function RoleDisplay(role: UserRole) {
  switch (role) {
    case "admin":
      return strings.UserManager.Roles.Admin;
    case "accounter":
      return strings.UserManager.Roles.Accounter;
    case "merchant":
      return strings.UserManager.Roles.Merchant;
    default:
      return strings.NoInfo;
  }
}
