export const ApiPath = {
  LOGIN: getFlexiblePath("user/login", "v1"),

  //Menu Config
  CONFIG: getFlexiblePath("config/detail", "v1"),

  //Sim Packages
  GET_SIM_PACKAGES_LIST: getFlexiblePath("sim/packages/list", "v1"),
  GET_PARTNER_LIST: getFlexiblePath("sim/partner/list", "v1"),
  GET_KIT_DETAIL: getFlexiblePath("sim/kit/detail", "v1"),
  GET_KIT_LIST: getFlexiblePath("sim/kit/list", "v1"),
  GET_EXPORT_EXCEL_SIM_KITS: getFlexiblePath("sim/kit/report", "v1"),

  //Data Packages
  GET_DATA_PACKAGES: getFlexiblePath("mobile/data/packages", "v2"),
  GET_DATA_PACKAGES_LIST: getFlexiblePath("mobile/data/packages", "v1"),
  GET_TRANSACTION: getFlexiblePath("mobile/data/transactions", "v2"),
  GET_DATA_GROUP: getFlexiblePath("mobile/data/group", "v1"),
  SET_TOP_PRIORITY: getFlexiblePath("mobile/data/suppliers/set-top-priority", "v2"),
  EXPORT_TRANSACTION: getFlexiblePath("mobile/data/transaction/export", "v1"),
  EXPORT_DATA_CODE_REPORT: getFlexiblePath(
    "mobile/data/transaction/export-statistic",
    "v1",
  ),
  SEARCH_INVENTORY: getFlexiblePath("merchant/inventory/search-from-warehouse", "v1"),
  IMPORT_INVENTORY_FROM_EXCEL: getFlexiblePath(
    "merchant/inventory/import",
    "v1",
  ),

  //Balance
  GET_BALANCE_REVIEW_LIST: getFlexiblePath("balanceticket/list", "v1"),
  GET_BALANCE_ACCEPTANCE: getFlexiblePath("balanceticket/review", "v1"),

  //Export Report
  GET_REPORTS: getFlexiblePath("reports/summary", "v1"),

  //Account
  GET_PROFILE: getFlexiblePath("user/get-profile", "v1"),

  //Account Authentication
  GET_SECRET_KEY: getFlexiblePath("user/2fa/token", "v1"),
  GET_OTP: getFlexiblePath("user/2fa/confirm-token", "v1"),

  //User
  DELETE_USER: getFlexiblePath("user/delete", "v1"),
  GET_LIST_USER: getFlexiblePath("user/get-list", "v1"),
  CREATE_USER: getFlexiblePath("user/signup", "v1"),
  POST_CHANGEPASSWORD: getFlexiblePath("user/edit-password", "v1"),

  //Revenue
  GET_C90N_REPORT: getFlexiblePath("sms/report/quantity", "v1"),
  GET_DASHBOARD_REPORT: getFlexiblePath("report/dashboard/sales", "v2"),
  DASHBOARD_EXPORT_EXCEL: getFlexiblePath("reports/sales/total", "v2"),

  //Chart
  GET_BAR_LINE_CHARTS: getFlexiblePath("reports/dashboard/trending", "v1"),
  GET_CIRCLE_CHARTS: getFlexiblePath("reports/dashboard/revenue", "v1"),

  //Merchant Management
  GET_MERCHANT: getFlexiblePath("merchants", "v1"),
  BALANCE_REQUEST: getFlexiblePath("balanceticket/add", "v1"),

  //Merchant Provider
  GET_MERCHANT_PROVIDERS: getFlexiblePath("smm/provider/list", "v1"),

  //Data group
  GET_SUPPLIER: getFlexiblePath("suppliers", "v1"),
};

export const packageRegistrationPaths = {
  PACKAGE_BY_PHONE: getFlexiblePath("mobile/data/phone", "v1"),
  PACKAGE_BY_MERCHANT: getFlexiblePath("mobile/data/merchant", "v1"),
  PACKAGE_REGISTRATION: getFlexiblePath("mobile/data/topup", "v5"),
  PACKAGE_PURCHASE: getFlexiblePath("mobile/data/purchase-package", "v1")
};

function getFlexiblePath(path: string, version: string) {
  return `${process.env.REACT_APP_API_URL!}/api/${version}/${path}`;
}

export default ApiPath;
