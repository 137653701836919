import { Color } from "components/consts/Consts";

export const ColorFilter = (color?: string) => {
  switch (color) {
    case Color.PRIMARY:
      return "brightness(0) saturate(100%) invert(66%) sepia(76%) saturate(2920%) hue-rotate(351deg) brightness(100%) contrast(96%)";
    case Color.WHITE:
      return "brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(168%) hue-rotate(233deg) brightness(116%) contrast(100%)"
    default:
      return "none";
  }
}
